import {
	isMobile,
	isTouch,
	getMousePos,
	getViewportDimensions,
	isElementInViewport,
} from "./helpers";

export default class Cards {

	constructor () {
		// Initialize variables
		this.cards = [].slice.call(document.querySelectorAll('.card'));

		this.mousePos = {
			x: getViewportDimensions().width / 2,
			y: getViewportDimensions().height / 2,
		};

		this.lastScrolledPosition = {
			top: 0, left: 0
		};

		// Events
		if (isMobile && isTouch) {
			if (window.DeviceMotionEvent !== undefined)
				window.ondevicemotion = this.onDeviceMove.bind(this);
		} else {
			window.addEventListener('mousemove', this.onMouseMove.bind(this));
		}

		// Start Loop
		requestAnimationFrame(this.loop.bind(this));
	}

	onMouseMove (e) {
		this.mousePos = getMousePos(e);
	}

	onDeviceMove (e) {
		const vdim = getViewportDimensions(),
			scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;

		const wmin = 0,
			wmax = vdim.width,
			hmin = scrollTop,
			hmax = scrollTop + vdim.height;

		let x = this.mousePos.x + e.acceleration.x * 50,
			y = this.mousePos.y + e.acceleration.y * 50;

		if (x > wmax) x = wmax;
		else if (x < wmin) x = wmin;

		if (y > hmax) y = hmax;
		else if (y < hmin) y = hmin;

		this.mousePos = {x, y};
	}

	getScrolledMousePosition () {
		const scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop,
			scrollLeft =
				document.documentElement.scrollLeft || document.body.scrollLeft;

		if (this.lastScrolledPosition.left !== scrollLeft) {
			this.mousePos.x -= this.lastScrolledPosition.left;
			this.lastScrolledPosition.left = scrollLeft;
			this.mousePos.x += this.lastScrolledPosition.left;
		}

		if (this.lastScrolledPosition.top !== scrollTop) {
			this.mousePos.y -= this.lastScrolledPosition.top;
			this.lastScrolledPosition.top = scrollTop;
			this.mousePos.y += this.lastScrolledPosition.top;
		}
	}

	rotateCards () {
		let i = this.cards.length;
		while (i--)
			if (isElementInViewport(this.cards[i]))
				this.rotateCard(this.cards[i]);
	}

	rotateCard (card) {
		const
			angle = 10,
			screen = getViewportDimensions(),
			bodyRect = document.body.getBoundingClientRect(),
			cardRect = card.getBoundingClientRect();

		const
			offsetTop = cardRect.top - bodyRect.top,
			offsetLeft = cardRect.left - bodyRect.left,
			offsetHeight = card.offsetHeight / 2;

		const
			degX = screen.width / angle,
			degY = screen.height / angle;

		let rx = -((this.mousePos.y - (offsetTop + offsetHeight)) / degY),
			ry = ((this.mousePos.x - (offsetLeft + offsetHeight)) / degX);

		card.style.transform = `
			rotateX(${rx}deg)
			rotateY(${ry}deg)
		`;
	}

	loop () {
		this.getScrolledMousePosition();
		this.rotateCards();
		requestAnimationFrame(this.loop.bind(this));
	}

}