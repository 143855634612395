export default class Learn {
	
	constructor () {
		this.active = -1;
		
		this.catsCont = document.getElementById("categories");
		this.catsById = {};
		
		this.elems = [].slice.call(document.getElementById("elems")
		                                   .getElementsByTagName('div'));
		
		
		[].slice.call(this.catsCont.getElementsByTagName('a')).forEach(cat => {
			this.catsById[cat.dataset.id] = cat;
			
			cat.addEventListener("click", e => {
				e.preventDefault();
				this.changeActive(cat.dataset.id);
			});
		});
	}
	
	changeActive (id) {
		this.catsById[this.active].classList.remove("active");
		this.active = +id;
		
		this.catsById[this.active].classList.add("active");
		this.elems.forEach(elem => {
			if (+elem.dataset.id === this.active || this.active === -1)
				this.addToDOM(elem);
			else
				this.removeFromDOM(elem);
		});
	}
	
	removeFromDOM (elem) {
		if (elem.isHidden)
			return;
		
		elem.isHidden = true;
		
		if (!elem.com)
			elem.com = document.createComment("Node");
		
		if (!elem.nextElementSibling)
			elem.parentNode.appendChild(elem.com);
		else
			elem.parentNode.insertBefore(elem.com, elem.nextElementSibling);
		
		elem.parentNode.removeChild(elem);
	}
	
	addToDOM (elem) {
		if (!elem.isHidden)
			return;
		
		elem.isHidden = false;
		
		if (!elem.com)
			return;
		elem.com.parentNode.insertBefore(elem, elem.com);
		elem.com.parentNode.removeChild(elem.com);
	}
	
}