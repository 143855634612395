import anime from 'animejs';
import { isMobile } from "./helpers";

export class Keyframe {
	
	/**
	 * @param {Number} delay - The time in milliseconds since the last frame
	 * @param {Function} action - The action to be run on this frame
	 * @constructor
	 */
	constructor (delay, action) {
		this.delay = delay;
		this.action = action;
	}
	
}

export default class Intro {
	
	// Constructor
	// =========================================================================

	constructor () {
		if (isMobile) return;
		
		this.intro = document.getElementById('intro');
		this.flockVideo = document.getElementById('flockVideo');
		
		if (!this.intro) {
			if (this.flockVideo) this.flockVideo.play();
			return;
		}
		
		this._keyframes = {};
		this._now = 0;
		this._then = Date.now();
		this.delta = 0;
		this.elapsed = 0;
		
		this.logo = document.getElementById('introLogo');
		this.thrive = document.getElementById('thrive');
		this.believe = document.getElementById('believe');
		this.power = document.getElementById('power');
		this.logoText = document.getElementById('introTextLogo');

		this.logo.balls = [].slice.call(this.logo.querySelectorAll('circle:not(.center)'));
		this.logo.centerBall = this.logo.getElementsByClassName('center')[0];

		this.centerLogoBalls();
		
		this.setKeyframes([
			
			new Keyframe(1000, this.show.bind(this, 'thrive')),
			new Keyframe(2500, this.startCenterShake.bind(this)),
			new Keyframe(1000, this.hide.bind(this, 'thrive')),
			new Keyframe(1000, this.show.bind(this, 'believe')),
			new Keyframe(1500, this.show.bind(this, 'power')),
			new Keyframe(1500, this.explodeLogoBalls.bind(this)),
			new Keyframe(100, this.stopCenterShake.bind(this)),
			new Keyframe(50, this.nudge.bind(this, 'believe')),
			new Keyframe(50, this.nudge.bind(this, 'power')),
			new Keyframe(3000, this.hide.bind(this, 'power')),
			new Keyframe(50, this.hide.bind(this, 'believe')),
			new Keyframe(100, this.show.bind(this, 'logoText')),
			new Keyframe(5000, this.endIntro.bind(this))
			
		]);
		
		this.loop();
	}
	
	// Core
	// =========================================================================
	
	/**
	 * Set the keyframes
	 *
	 * @param {Keyframe[]} keyframes
	 */
	setKeyframes (keyframes = []) {
		let i = -1,
			duration = 0,
			len = keyframes.length - 1;
		while (i++ < len) {
			let frame = keyframes[i];
			duration += frame.delay;
			this._keyframes[duration] = frame.action;
		}
	}
	
	loop () {
		// Calculate delta time
		this._now = Date.now();
		this.delta = (this._now - this._then);
		this._then = this._now;
		this.elapsed += this.delta;
		
		// Loop over our keyframes & play if necessary
		for (let wait in this._keyframes) {
			if (!this._keyframes.hasOwnProperty(wait)) continue;
			if (+this.elapsed >= +wait) {
				this._keyframes[wait]();
				delete this._keyframes[wait];
			}
		}
		
		// Continue Loop
		this.animationLoop = requestAnimationFrame(this.loop.bind(this));
	}
	
	// "Frames"
	// =========================================================================
	
	centerLogoBalls () {
		const center = {
			x: 395.63 / 2,
			y: (285.86 / 2) + 35,
		};

		let i = this.logo.balls.length;
		while (i--) {
			const ball = this.logo.balls[i];
			const cx = +ball.getAttribute('cx'),
				cy = +ball.getAttribute('cy');

			let x, y;

			if (cx > center.x) x = -(cx - center.x);
			else x = center.x - cx;

			if (cy > center.y) y = -(cy - center.y);
			else y = center.y - cy;

			ball.style.transform = `
				translateX(${x}px) 
				translateY(${y}px)
				scale(0)
			`;
		}
	}
	
	show (id) {
		this[id].classList.add('enter');
	}
	
	nudge (id) {
		this[id].classList.add('nudge');
	}
	
	hide (id) {
		this[id].classList.remove('enter');
		this[id].classList.add('exit');
	}

	startCenterShake () {
		let dist = 10;

		anime({
			targets: this.logo.centerBall,
			translateX: [-dist, dist],
			easing: 'linear',
			direction: 'alternate',
			loop: true,
			duration: 30,
		});
	}
	
	stopCenterShake () {
		anime.remove(this.logo.centerBall);
		
		anime({
			targets: this.logo.centerBall,
			translateX: [-10, 0],
			duration: 1000,
		});
	}

	explodeLogoBalls () {
		anime({
			targets: this.logo.balls,
			translateX: 0,
			translateY: 0,
			scale: [0,1],
			delay: function(el, index) {
				return index * anime.random(2, 20);
			},
			duration: 1500,
			elasticity: 700,
			easing: 'easeOutElastic',
		});
	}
	
	endIntro () {
		document.body.classList.remove('intro-playing');
		cancelAnimationFrame(this.animationLoop);
		if (this.flockVideo) this.flockVideo.play();
		
		setTimeout(() => {
			document.body.removeChild(this.intro);
		}, 3000);
	}

}