/**
 * Selects a random number from a range
 *
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function random (min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const isMobile = /Mobi/.test(navigator.userAgent);

export const isTouch = (() => {
	try {
		document.createElement('TouchEvent');
		return true;
	} catch (e) {
		return false;
	}
})();

/**
 * Gets the mouse position from a move event
 *
 * @param {Event} e
 * @returns {{x: number, y: number}}
 */
export function getMousePos(e) {
	let posx = 0, posy = 0;
	if (!e)  e = window.event;
	if (e.pageX || e.pageY) 	{
		posx = e.pageX;
		posy = e.pageY;
	}
	else if (e.clientX || e.clientY) 	{
		posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
		posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
	}
	return { x : posx, y : posy };
}

/**
 * Gets the dimensions of the viewport
 *
 * @returns {{width: (number), height: (number)}}
 */
export function getViewportDimensions () {
	const w = window,
		d = document,
		e = d.documentElement,
		b = document.body;

	const width = w.innerWidth || e.clientWidth || b.clientWidth,
		height = w.innerHeight || e.clientHeight || b.clientHeight;

	return { width, height };
}

/**
 * Is element in the viewport
 *
 * @param {Element} el
 * @param {number=} offset
 * @returns {boolean}
 */
export function isElementInViewport (el, offset = 0) {
	const rect = el.getBoundingClientRect(),
		vdim = getViewportDimensions();

	return (
		(rect.top - vdim.height) <= offset &&
		(rect.left - vdim.width) <= offset &&
		rect.bottom >= -offset &&
		rect.right >= -offset
	);
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * clamp(x * 255, 0, 255)
 *
 * @param {Number} number
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export function clamp (number, min, max) {
	return Math.min(Math.max(number, min), max);
}

/**
 * Shuffles the order of elements in an array
 *
 * @param {Array} array The array to shuffle
 * @return {Array}
 */
export function shuffle (array) {
	array = array.slice();
	let m = array.length, t, i;
	
	// While there remain elements to shuffle…
	while (m) {
		
		// Pick a remaining element…
		i = Math.floor(Math.random() * m--);
		
		// And swap it with the current element.
		t = array[m];
		array[m] = array[i];
		array[i] = t;
	}
	
	return array;
}

/**
 * Converts a string to camelCase
 *
 * @param {string} str
 * @return {string}
 */
export function camelCase(str) {
	return str
		.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, " ")
		.split(' ')
		.map((word, i) => {
			word = word.toLowerCase();
			if (i !== 0) word = word[0].toUpperCase() + word.slice(1);
			return word;
		}).join('');
}