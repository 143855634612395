import { random, isMobile } from "./helpers";
import anime from "animejs";

export default class Buttons {

	constructor () {
		if (isMobile) return;

		const buttons = [].slice.call(document.getElementsByClassName('button'));
		let i = buttons.length;
		while (i--)
			this.init(buttons[i]);
	}

	init (button) {
		const minSize = 5,
			maxSize = 30;

		let blobCount = random(5, 10),
			blobs = [];

		// Create blobs
		while (blobCount--) {
			const blob = document.createElement('span'),
				size = random(minSize, button.height < maxSize ? button.height : maxSize);
			blob.className = 'blob';
			blob.style.width = size + 'px';
			blob.style.height = size + 'px';
			blob.size = size < 20 ? 20 : size;
			blob.endX = random(0, 50 - size);
			blob.endY = random(0, 50 - size);
			blobs.push(blob);

			button.insertBefore(blob, button.lastElementChild);
		}

		// Split blobs into two & position
		const firstStart = random(0,3),
			lastStart = 3 - firstStart;

		let i = blobs.length;

		while (i--) {
			const blob = blobs[i];

			switch (i <= blobs.length / 2 ? lastStart : firstStart) {
				case 0: // Top Left
					blob.style.top = 0;
					blob.style.left = 0;
					blob.style.transform = `translateX(${blob.size}px) translateZ(0)`;
					blob.vector = [-1,-1];
					break;
				case 1: // Top Right
					blob.style.top = 0;
					blob.style.right = 0;
					blob.style.transform = `translateX(-${blob.size}px) translateZ(0)`;
					blob.vector = [1,-1];
					break;
				case 2: // Bottom Left
					blob.style.bottom = 0;
					blob.style.left = 0;
					blob.style.transform = `translateX(${blob.size}px) translateZ(0)`;
					blob.vector = [-1,1];
					break;
				case 3: // Bottom Right
					blob.style.bottom = 0;
					blob.style.right = 0;
					blob.style.transform = `translateX(-${blob.size}px) translateZ(0)`;
					blob.vector = [1,1];
					break;
			}
		}

		button.addEventListener('mouseenter', () => {
			anime.remove(blobs);
			anime({
				targets: blobs,
				translateX: function (el) {
					return el.vector[0] < 0 ? -el.endX : el.endX;
				},
				translateY: function (el) {
					return el.vector[1] < 0 ? -el.endY : el.endY;
				},
				translateZ: 0,
				delay: function(el) {
					return (maxSize - el.size) * 7;
				},
				// scaleX: [.1,1],
				// scaleY: [.1,1],
				// scaleZ: [1,1],
				duration: 300,
			});
		});

		button.addEventListener('mouseleave', () => {
			anime.remove(blobs);
			anime({
				targets: blobs,
				translateX: function (el) {
					return el.vector[0] < 0 ? el.size : -el.size;
				},
				translateY: 0,
				translateZ: 0,
				duration: 100,
				// scaleX: [1,.1],
				// scaleY: [1,.1],
				// scaleZ: [1,1],
				easing: 'easeInCirc',
			});
		});

	}

}