import { shuffle, random, camelCase, isMobile } from "./helpers";
// import companyNames from "./data/companyNames";
// import categories from "./data/categories";

export default class LiveMap {
	
	constructor () {
		this.map = document.getElementById('totally100PercentLegitActualRealTimeLiveMap');
		let dots = [].slice.call(this.map.querySelectorAll('[data-city]'));
		
		this.cities = dots.reduce((a, b) => {
			a[camelCase(b.dataset.city)] = b;
			return a;
		}, {});
		
		this.activeCities = [];
		
		// Remove dots that might have bubbles
		let bubbleCities = [
			...new Set(window.bubbles.map(bubble => bubble.city))
		];
		
		this.dots = dots.reduce((a, b) => {
			if (bubbleCities.indexOf(camelCase(b.dataset.city)) === -1)
				a.push(b);
			return a;
		}, []);
		
		this.changeDots('primary');
		this.changeDots('secondary');
		this.changeDots('tertiary');
		
		this.previousPosition = -1;
		this.previousData = {type:''};
		
		setInterval(() => {
			this.changeDots('primary');
			this.changeDots('secondary');
			this.changeDots('tertiary', 1);
		}, 1000);
		
		setInterval(() => {
			this.createBubble();
		}, window.innerWidth > 960 ? 4000 : 7000);
	}
	
	changeDots (className, change = 3) {
		let dots = shuffle(this.dots).slice(0, change);
		dots.forEach(dot => {
			if (+dot.getAttribute('cy') < 207) return;
			
			setTimeout(() => {
				dot.classList.add(className);
			}, random(100, 700));
			
			setTimeout(() => {
				dot.classList.remove(className);
			}, random(3000, 15000));
		});
	}
	
	createBubble () {
		let wrap = document.createElement('div'),
			bubble = document.createElement('div'),
			inner = document.createElement('div'),
			strong = document.createElement('strong'),
			img = document.createElement('img'),
			span   = document.createElement('span'),
			usingCity = false;
		
		wrap.classList.add('bubble-wrap');
		wrap.appendChild(bubble);
		
		bubble.classList.add('bubble');
		bubble.classList.add('in');
		
		bubble.appendChild(inner);
		
		inner.appendChild(strong);
		inner.appendChild(span);
		
		let bubbleData = shuffle(window.bubbles)[0],
			i = 0;
		while (
			(bubbleData === this.previousData ||
			bubbleData.type === this.previousData.type ||
			this.activeCities.indexOf(bubbleData.city) > -1) &&
			i++ < 5
		) bubbleData = shuffle(window.bubbles)[0];
			
		this.previousData = bubbleData;
		
		bubble.classList.add(bubbleData.type);
		
		strong.textContent = bubbleData.name;
		span.textContent = bubbleData.project;
		
		if (bubbleData.logo) {
			img.src = bubbleData.logo;
			bubble.insertBefore(img, bubble.firstElementChild);
		}
		
		if (isMobile) {
			
			wrap.style.top = '50%';
			wrap.style.left = '50%';
			wrap.style.transform =
				`translate3d(-50%, -50%, 0)`;
			
		} else if (this.cities.hasOwnProperty(bubbleData.city)) {
			let city = this.cities[bubbleData.city],
				cPos = city.parentNode.getBoundingClientRect(),
				pos = city.getBoundingClientRect();
			
			let t = pos.top - cPos.top,
				l = pos.left - cPos.left;
			
			wrap.style.top = `${t + 10}px`;
			wrap.style.left = `${l + 10}px`;
			
			usingCity = true;
			city.classList.add(bubbleData.type);
			this.activeCities.push(bubbleData.city);
		} else {
			let newPosition = random(0, 3);
			while (newPosition === this.previousPosition)
				newPosition = random(0, 3);
			
			let t, r, b, l;
			
			switch (newPosition) {
				case 0:
					t = random(10, 50);
					l = random(10, 50);
					wrap.style.top = t + '%';
					wrap.style.left = l + '%';
					wrap.style.transform =
						`translate3d(-${l > 40 ? 85 : 0}%, -${t > 40 ? 85 : 0}%, 0)`;
					break;
				case 1:
					t = random(10, 50);
					r = random(10, 50);
					wrap.style.top = t + '%';
					wrap.style.right = r + '%';
					wrap.style.transform =
						`translate3d(${r > 40 ? 85 : 0}%, -${t > 40 ? 85 : 0}%, 0)`;
					break;
				case 2:
					b = random(10, 50);
					l = random(10, 50);
					wrap.style.bottom = b + '%';
					wrap.style.left = l + '%';
					wrap.style.transform =
						`translate3d(-${l > 40 ? 85 : 0}%, ${b > 40 ? 85 : 0}%, 0)`;
					break;
				case 3:
					b = random(10, 50);
					r = random(10, 50);
					wrap.style.bottom = b + '%';
					wrap.style.right = r + '%';
					wrap.style.transform =
						`translate3d(${r > 40 ? 85 : 0}%, ${b > 40 ? 85 : 0}%, 0)`;
					break;
			}
			
			this.previousPosition = newPosition;
		}
		
		this.map.parentNode.appendChild(wrap);
		bubble.offsetHeight; // Trigger re-paint
		bubble.classList.remove('in');
		
		setTimeout(() => {
			bubble.classList.add('out');
			
			if (usingCity)
				this.cities[bubbleData.city].classList.remove(bubbleData.type);
			
			setTimeout(() => {
				wrap.parentNode.removeChild(wrap);
				
				if (usingCity)
					this.activeCities.splice(
						this.activeCities.indexOf(bubbleData.city), 1);
			}, 400);
		}, 7000);
		
	}
	
}