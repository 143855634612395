/**
 * Modals
 * ---
 * <div class="modal-overlay" data-modal="myModal"> [...] </div>
 * <a href="#" data-open-modal="myModal">Open My Modal</a>
 * <a href="#" data-close-modal="myModal">Close My Modal</a>
 */
class ModalsManager {
	
	constructor () {
		const modals = [...document.querySelectorAll('[data-modal]')]
			, triggers = [...document.querySelectorAll('[data-open-modal]')]
			, closers = [...document.querySelectorAll('[data-close-modal]')];
		
		this.modals = {};
		this.eventListeners = {
			open: [],
			close: [],
		};
		this.activeModal = "";
		
		let i = modals.length;
		while (i--) {
			let modal = modals[i];
			this.modals[modal.dataset.modal] = modal;
			modal.addEventListener('click', e => {
				if (e.target !== modal) return;
				this.close(modal.dataset.modal);
			});
		}
		
		i = triggers.length;
		while (i--) {
			let trigger = triggers[i];
			trigger.addEventListener('click', e => {
				e.preventDefault();
				this.open(trigger.dataset.open);
			});
		}
		
		i = closers.length;
		while (i--) {
			let closer = closers[i];
			closer.addEventListener('click', e => {
				e.preventDefault();
				this.close(closer.dataset.close);
			});
		}
		
		return this;
	}
	
	/**
	 * Open a modal
	 *
	 * @param {string} name - Name of the modal to open
	 */
	open (name) {
		if (!this._doesModalExist(name)) return;
		
		if (this.activeModal !== "" && this.activeModal !== name)
			this.close();
		
		document.body.classList.add('modal-open');
		this.modals[name].classList.add('open');
		this.activeModal = name;
		
		this.eventListeners.open.map(callback => {
			callback();
		});
	}
	
	/**
	 * Close a modal
	 *
	 * @param {string=} name - Name of the modal to close
	 */
	close (name = this.activeModal) {
		if (!this._doesModalExist(name)) return;
		
		document.body.classList.remove('modal-open');
		this.modals[name].classList.remove('open');//
		this.activeModal = "";
		
		this.eventListeners.close.map(callback => {
			callback();
		});
	}
	
	/**
	 * Event listener for modals
	 *
	 * @param {string} event
	 * @param {string} name
	 * @param {function} callback
	 */
	on (event, name, callback) {
		if (!this._doesModalExist(name)) {
			console.error(`Unable to find modal: ${name}`); // eslint-disable-line no-console
			return;
		}
		
		if (!this.eventListeners.hasOwnProperty(event)) {
			console.error(`Modal event not valid: ${event}`); // eslint-disable-line no-console
			return;
		}
		
		this.eventListeners[event].push(callback);
	}
	
	/**
	 * Check if a modal exists
	 *
	 * @param {string} name - Name of the modal
	 * @return {boolean}
	 * @private
	 */
	_doesModalExist (name) {
		return this.modals.hasOwnProperty(name);
	}
	
}

const Modals = new ModalsManager();
export default Modals;