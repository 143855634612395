import anime from 'animejs';

export default class Header {

	constructor () {
		this.navA = document.getElementById('nav').getElementsByTagName('a');
		this.toggleNav();
		
		window.closeNav = this.closeNav.bind(this);
	}

	toggleNav () {
		document.getElementById('toggleNav').addEventListener('click', e => {
			e.preventDefault();
			document.body.classList.toggle('nav-open');
			
			if (document.body.classList.contains('nav-open')) {
				anime.remove(this.navA);
				
				// In
				anime({
					targets: this.navA,
					translateX: '-100%',
					delay: function(el, index) {
						return index * 80 + 300;
					},
					duration: function(el, index) {
						return index * 10 + 800;
					}
				});
			} else {
				this.closeNav();
			}
		});
	}
	
	closeNav () {
		anime.remove(this.navA);
		
		// Out
		anime({
			targets: this.navA,
			translateX: '0%',
			duration: 2000
		});
		
		document.body.classList.remove('nav-open');
	}

}