export default class Scroll {
	
	constructor () {
		const triggers = [...document.querySelectorAll('[data-scroll]')];
		
		let i = triggers.length;
		while (i--) {
			const trigger = triggers[i];
			trigger.scrollTarget = document.getElementById(
				trigger.getAttribute('href').slice(1)
			);
			trigger.addEventListener('click', e => {
				e.preventDefault();
				window.closeNav();
				this.scrollTo(trigger.scrollTarget, 50);
			});
		}
	}
	
	/**
	 * Scrolls the page to an element
	 *
	 * @param {HTMLElement} element - Target element
	 * @param {number=} topOffset - Space above to scroll to (can be negative)
	 */
	scrollTo (element, topOffset = 0) {
		let startPosition = (document.documentElement.scrollTop || document.body.scrollTop),
			endPosition = this._getCoords(element).top - topOffset,
			startTime = null;
		
		const loop = (time) => {
			if ( !startTime )
				startTime = time;
			
			let timeSoFar = time - startTime;
			let easedPosition = this._easeInOutQuad(timeSoFar, startPosition, endPosition - startPosition, 1000);
			
			window.scrollTo(0, easedPosition);
			
			if( timeSoFar < 1000 )
				requestAnimationFrame(loop);
		};
		
		requestAnimationFrame(loop);
	}
	
	// Private
	// =========================================================================
	
	/**
	 * Gets the coordinates of an element on the page
	 *
	 * @param {HTMLElement} elem - Target element
	 * @return {{top: number, left: number}}
	 * @private
	 */
	_getCoords(elem) {
		let box = elem.getBoundingClientRect();
		
		let body = document.body;
		let docEl = document.documentElement;
		
		let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
		
		let clientTop = docEl.clientTop || body.clientTop || 0;
		let clientLeft = docEl.clientLeft || body.clientLeft || 0;
		
		let top  = box.top +  scrollTop - clientTop;
		let left = box.left + scrollLeft - clientLeft;
		
		return { top: Math.round(top), left: Math.round(left) };
	}
	
	/**
	 * In/Out Quad easing
	 *
	 * @param t
	 * @param b
	 * @param c
	 * @param d
	 * @return {*}
	 * @private
	 */
	_easeInOutQuad( t, b, c, d ) {
		t /= d / 2;
		if ( t < 1 ) return c / 2 * t * t + b;
		t--;
		return -c / 2 * ( t * ( t -2 ) - 1 ) + b;
	}
	
}