import Header from "./header";
import Buttons from "./buttons";
import Cards from "./cards";
import Intro from "./intro";
// import CircleInfo from "./circleInfo";
import LiveMap from "./liveMap";
import Modals from "./modals";
import Scroll from "./scroll";
import Learn from "./learn";

function onDOMLoad () {
	document.removeEventListener("DOMContentLoaded", onDOMLoad);
	
	new Header();

	switch (window.p) {
		case "index": {
			new Buttons();
			new Cards();
			new Intro();
			// new CircleInfo();
			new LiveMap();
			new Scroll();
			break;
		}
		
		case "learn": {
			new Learn();
			break;
		}
	}
	
	// Index Newsletter Annoyer
	// =========================================================================
	const annoyer = document.getElementById('annoyer');
	if (annoyer) {
		const showAnnoyer = () => {
			if (document.hasFocus())
				Modals.open('annoyer');
		};
		
		Modals.on('close', 'annoyer', () => {
			window.removeEventListener('mouseout', showAnnoyer);
		});
		
		window.addEventListener('mouseout', showAnnoyer);
		annoyer.querySelector('form').addEventListener('submit', () => {
			Modals.close('annoyer');
		});
	}
}

function onResourceLoad () {
	window.removeEventListener("load", onResourceLoad);

	// ...
}

document.addEventListener("DOMContentLoaded", onDOMLoad);
window.addEventListener("load", onResourceLoad);
